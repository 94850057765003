import React from 'react'
import axios from "axios";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

const Gallery  = () => {

    const [images, setImages] = React.useState(null)

    React.useEffect(() => {
        let shouldCancel = false
        const call = async () => {
        const response = await axios.get(
            'https://google-photos-album-demo2.glitch.me/EXZ9ePxEnre9obiT6'
        );
        if (!shouldCancel && response.data && response.data.length > 0) {
            setImages(response.data.map(url => ({
            original: `${url}=w1024`,
            thumbnail: `${url}=w100`
            })))
        }
        }
        call()
        return () => shouldCancel = true
    }, [])
    
    return (
            <section className="section">
                <div className="container">
                    <h2 className="title">Gallerie Photos</h2>
                    <h3 className="subtitle">Voici quelques exemples de nos travaux</h3>
                    <div className="content">
                        {images ? <ImageGallery items={images} autoPlay={true} /> : null}
                    </div>
                </div>
            </section>
    );
}

export default Gallery;
