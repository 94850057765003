import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScrollAnimation from 'react-animate-on-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery"

import img1 from "../images/home1.jpg"
import carte from "../images/carte_aws.svg"
import airConditioner from "../images/air-conditioner.svg"
import heater from "../images/heater.svg"
import { faMapMarkerAlt, faPhone, faWrench, faFaucet, faBookOpen, faStar } from '@fortawesome/free-solid-svg-icons'

const IndexPage = () => (
  <Layout>
    <SEO title="Entreprise de plomberie, climatisation dans le Rhône" />
    <section className="hero is-fullheight-with-navbar is-bold has-background">
      <img className="hero-background" alt="© Ákos Szabó from Pexels" src={img1} />
      <div className="hero-body">
        <div className="container">
          <h2 className="title has-text-white">
            Air Water System
          </h2>
          <h3 className="subtitle">
            Entreprise de plomberie, chauffage et climatisation dans le Rhône
          </h3>
          <div style={{marginBottom:24}}>
            <span class="icon is-large" style={{marginRight:18}}><img alt="" src={airConditioner} width="32" height="32"></img></span>
            <span class="icon is-large" style={{marginRight:18}}><img alt="" src={heater} width="32" height="32"></img></span>
            <span class="icon is-large" style={{marginRight:18}}><FontAwesomeIcon icon={faWrench} size="2x" color="white" /></span>
            <span class="icon is-large"><FontAwesomeIcon icon={faFaucet} size="2x" color="white" /></span>
          </div>
          <div className="content">
            <div class="buttons">
                <Link to="/contact#adresse" class="button is-info">
                  <span class="icon"><FontAwesomeIcon icon={faMapMarkerAlt} /></span>
                  <strong>4 rue Chopin, 69780 Mions</strong>
                </Link>
                <ScrollAnimation animateIn="heartBeat" duration="1" animateOnce={false}>
                  <a href="tel:0698999696" class="button is-success">
                    <span class="icon"><FontAwesomeIcon icon={faPhone} /></span>
                    <strong>06 98 99 96 96</strong>
                  </a>
                </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ScrollAnimation animateIn="fadeIn" duration="2" animateOnce={true} offset={300}>
      <section className="section">
        <div className="container">
          <h1 className="title"> Entreprise de plomberie, chauffage et climatisation dans le Rhône </h1>
          <p className="content">
            Êtes-vous à la recherche d’un <strong>artisan de confiance</strong> à qui confier vos travaux ?
            <br></br>
            Air Water System met à votre disposition des <strong>artisans compétents</strong> qui sauront vous prodiguer des conseils judicieux pour votre entretien mais également votre installation.
          </p>
        </div>
      </section>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeIn" duration="2" animateOnce={true} offset={300}>
      <section className="section">
        <div className="container">
          <h2 className="title"> Passionnés de la maîtrise des énergies thermiques </h2>
          <p className="content">
            Quelle que soit la nature du défi en <strong>climatisation, chauffage, ventilation, adoucisseur d'eau et plomberie</strong> que vous avez, faites confiance à l’expertise de nos équipes. Nous assurons un <strong>dépannage rapide</strong>, que vous soyez à Mions ou dans le Rhône.
          </p>
        </div>
      </section>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeIn" duration="2" animateOnce={true} offset={300}>
      <Gallery></Gallery>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeIn" duration="2" animateOnce={true} offset={300}>
      <section className="section has-background-primary">
        <div className="container">
          <h2 className="title has-text-white">Nos services pour les particuliers et professionnels</h2>
          <h3 className="subtitle has-text-white-ter">
            <Link to="/climatisation-lyon" className="has-text-white-ter is-hoverable">Climatisation à Lyon</Link> | <Link to="/climatisation" className="has-text-white-ter is-hoverable">Climatisation à Mions</Link> | <Link to="/plomberie" className="has-text-white-ter is-hoverable">Plombier à Mions</Link>
          </h3>
          <div className="columns">
            <Link to="/climatisation-lyon" className="column has-text-centered is-hoverable">
              <div className="service">
                <p className="title">
                  <img alt="" src={airConditioner} width="64" height="64"></img>
                </p>
                <p className="heading has-text-light">Climatisation</p>
              </div>
            </Link>
            <Link to="/chauffage" className="column has-text-centered is-hoverable">
              <div className="service">
                <p className="title">
                  <img alt="" src={heater} width="64" height="64"></img>
                </p>
                <p className="heading has-text-light">Chauffage</p>
              </div>
            </Link>
            <Link to="/plomberie" className="column has-text-centered is-hoverable">
              <div className="service">
                <p className="title has-text-white">
                  <FontAwesomeIcon icon={faWrench} size="2x"/>
                </p>
                <p className="heading has-text-light">Plomberie</p>
              </div>
            </Link>
            <Link to="/adoucisseur" className="column has-text-centered is-hoverable">
              <div className="service">
                <p className="title has-text-white">
                  <FontAwesomeIcon icon={faFaucet} size="2x"/>
                </p>
                <p className="heading has-text-light">Adoucisseur d'eau</p>
              </div>
            </Link>
            <Link to="/" className="column has-text-centered is-hoverable">
              <div className="service">
                <p className="title has-text-white">
                  <FontAwesomeIcon icon={faBookOpen} size="2x"/>
                </p>
                <p className="heading has-text-light">Catalogue produit</p>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeIn" duration="2" animateOnce={true} offset={300}>
      <section className="section">
        <div className="container">
          <h3 className="title"> Votre entreprise de plomberie </h3>
          <p className="content">
            Confiez vos <strong>travaux de plomberie</strong> à Air Water System votre <Link to="/plomberie">plombier à Mions</Link>, nos équipes sont déployées dans les plus brefs délais pour l’entretien et la rénovation de votre système de robinetterie, déboucher la tuyauterie et si nécessaire, restaurer entièrement le <strong>système d’assainissement</strong>. 
            <br></br>
            <br></br>
            Nous assurons un dépannage rapide en <strong>plomberie</strong>, fuites d’eaux fréquentes, problème d’évacuation de l’eau dans les canalisations, tuyaux bouchés, sanitaire en panne, etc.
          </p>
        </div>
      </section>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeIn" duration="2" animateOnce={true} offset={300}>
      <section className="section">
        <div className="container">
          <h3 className="title"> Votre installateur de climatisation </h3>
          <p className="content">
            Un <strong>climatiseur</strong> dépend en grande partie votre niveau de confort dans votre bureau ou dans votre salon. En effet, grâce à son circuit frigorifique, cet appareil se charge de l’évacuation de la chaleur dans votre pièce. Lorsqu’il est réversible, le <strong>climatiseur</strong>, en plus de contribuer au refroidissement de votre pièce peut également servir à la <strong>chauffer</strong>. Il n’est donc pas uniquement réservé à la période estivale. <strong>L’échange thermique</strong> est assuré par un fluide réfrigérant. Si votre clim fait trop de bruit ou si vous voulez optimiser la qualité de l’air à l’intérieur de vos pièces, n’hésitez pas à prendre contact avec nous.
            <br></br>
            <br></br>
            Nos <strong>artisans</strong> climatisation <Link to="/climatisation">à Mions</Link> et <Link to="/climatisation-lyon">à Lyon</Link> se chargent d’installer l’appareil dans le strict respect des normes en la matière en sorte d’en prolonger la durée de vie. Toutefois, si au cours du fonctionnement de l’appareil vous constatez de la condensation chez vous, nous intervenons rapidement pour éliminer l’air intérieur vicié qui est préjudiciable à votre bien-être.
            <br></br>
            <br></br>
            Une <strong>maintenance régulière</strong> est requise pour profiter pleinement de votre clim. Elle consiste à nettoyer les filtres, contrôler la ventilation, la pompe à chaleur et l’état des <strong>radiateurs</strong>. Il s'agit également de vérifier le niveau de gaz ainsi que <strong>l’étanchéité</strong> et appliquer des produits pour éviter le développement de moisissures et champignons. Il n’y a qu’un professionnel pour s’en occuper !
          </p>
        </div>
      </section>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeIn" duration="2" animateOnce={true} offset={300}>
      <section className="section">
        <div className="container">
          <h3 className="title"> Votre installateur de chauffage </h3>
          <p className="content">
            Vous doutez de la qualité du fioul utilisé dans votre <strong>chaudière</strong> ? Vous souhaitez remplacer le thermostat de votre chaudière ? Vous désirez installer un système de chauffage central qui allie économie d’énergie et praticité ? <strong>Air Water System</strong> vous en propose de deux types : le plancher chauffant et le chauffage solaire. Le premier comprend un ensemble de tubes encastrés dans le sol. <strong>L’eau chauffée</strong> y circule à une température comprise entre 21 et 24°C. Ce système de chauffage utilise la surface du sol comme un grand radiateur.
            <br></br>
            <br></br>
            Le <strong>chauffage solaire</strong> est un système composé de capteurs vitrés installés sur la toiture. Le rayonnement solaire chauffe un fluide caloporteur qui à son tour cède la chaleur à un <strong>chauffe eau</strong>. Tout en respectant vos exigences, nous nous chargeons d’installer pour vous une solution adaptée à votre contexte.
          </p>
        </div>
      </section>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeIn" duration="2" animateOnce={true} offset={300}>
      <section className="section">
        <div className="container">
          <h3 className="title"> Les systèmes performants pour adoucir l’eau </h3>
          <p className="content">
            Un <strong>adoucisseur d’eau</strong> est utile pour le traitement de l’eau. Air Water System se charge de l’installation de votre adoucisseur. Grâce à une résine échangeuse d’ions, l’appareil permet de réduire le calcaire contenu dans l’eau de robinet pour en limiter les effets sur la peau. L’eau adoucie n’aura aucun impact négatif sur votre canalisation. En outre, nous vous proposons des dispositifs de <strong>traitement de l’eau</strong> qui maintiendront votre peau adoucie.
          </p>
        </div>
      </section>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeIn" duration="2" animateOnce={true} offset={300}>
      <section className="section">
        <p className="content has-text-centered is-size-4">
            <em>"Nous avons une longueur d’avance aujourd’hui dans les solutions d’efficacité énergétique."</em>
          </p>
          <div className="columns is-mobile">
            <div className="column is-2 is-offset-1 has-text-warning has-text-centered">
                <FontAwesomeIcon icon={faStar} size="3x"/>
            </div>
            <div className="column is-2 has-text-warning has-text-centered">
                <FontAwesomeIcon icon={faStar} size="3x"/>
            </div>
            <div className="column is-2 has-text-warning has-text-centered">
                <FontAwesomeIcon icon={faStar} size="3x"/>
            </div>
            <div className="column is-2 has-text-warning has-text-centered">
                <FontAwesomeIcon icon={faStar} size="3x"/>
            </div>
            <div className="column is-2 has-text-warning has-text-centered">
                <FontAwesomeIcon icon={faStar} size="3x"/>
            </div>
        </div>
        <p className="content has-text-centered">
            <strong>Faites confiance au sérieux et à la qualité de nos services sollicitez vos travaux auprès de Air Water System !</strong>
            <br></br>
            L'équipe saura vous conseiller sur les solutions les plus adaptées à votre maison et à votre budget.
        </p>
      </section>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeIn" duration="2" animateOnce={true} offset={300}>
      <section className="section">
        <div className="container">
          <h2 className="title">Vous avez besoin d'un dépannage ?</h2>
          <p className="content">
            Nos artisans expérimentés, se déplacent chez vous rapidement pour le dépannage ou l'entretien de votre chauffage, votre plomberie ou votre climatisation.
            <br></br>
            Soucieux de votre satisfaction, nous vous proposons des solutions efficaces et économiques.
            <br></br>
            Nos chauffagistes climaticiens vous accompagnent dans vos travaux de A à Z et vous garantissent un travail de qualité et à la hauteur de vos attentes.
            <br></br>
            Nous prenons en charge l’installation et la maintenance de tout type de chambre froide.
          </p>
        </div>
      </section>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeIn" duration="2" animateOnce={true} offset={300}>
      <section className="section">
        <div className="container">
          <h2 className="title">Les zones d’intervention pour vos travaux dans le Rhône</h2>
          <div className="columns">
            <div className="column">
              <p className="content">
                Nos plombiers climaticiens d’Air Water System interviennent auprès des particuliers et des professionnels pour faire profiter de leurs services autour de Mions, notamment à :
              </p>
              <div className="columns">
                <div className="column">
                  <ul className="list">
                    <li className="list-item">Saint-Priest</li>
                    <li className="list-item">Lyon</li>
                    <li className="list-item">Saint-Etienne</li>
                    <li className="list-item">Grenoble</li>
                    <li className="list-item">Villeurbanne</li>
                    <li className="list-item">Valence</li>
                  </ul>
                </div>
                <div className="column">
                  <ul className="list">
                    <li className="list-item">Vénissieux</li>
                    <li className="list-item">Chambéry</li>
                    <li className="list-item">Annecy</li>
                    <li className="list-item">Bourg-en-Bresse</li>
                    <li className="list-item">Roanne</li>
                    <li className="list-item">Vaulx-en-Velin</li>
                  </ul>
                </div>
                <div className="column">
                  <ul className="list">
                    <li className="list-item">Genas</li>
                    <li className="list-item">Saint-Fons</li>
                    <li className="list-item">Corbas</li>
                    <li className="list-item">Feyzin</li>
                    <li className="list-item">Chassieu</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="column">
              <figure className="image">
                <img alt="© Air Water System" src={carte}></img>
              </figure>
            </div>
          </div>
          <p className="content">  
            Pour plus de renseignement concernant l'installation ou la réparation de votre chauffage, votre plomberie ou votre climatisation, composez le <strong>06 98 99 96 96</strong>.
          </p>
        </div>
      </section>
    </ScrollAnimation>
  </Layout>
)

export default IndexPage
